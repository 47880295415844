import { permissions } from '@mpk/shared/domain';

enum DeploymentSettingsPermissionsEnum {
  None = 0,

  /**
   * Configure help center and feedback settings.
   */
  ManageSupportSettings = 1,
}

export const DeploymentSettingsPermissions = permissions(DeploymentSettingsPermissionsEnum, {
  featureId: 'DeploymentSettings',
});
