import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LetDirective } from '@ngrx/component';

import { HeaderElementDirective } from '@core/shared/util';
import {
  NavigateBackButtonComponent,
  NavigationTabsMenuComponent,
  RootModuleNavComponent,
  TabLink,
  ViewModule,
} from '@core/ui';

@Component({
  selector: 'mps-deployment-settings',
  standalone: true,
  templateUrl: './deployment-settings.component.html',
  styleUrls: ['./deployment-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LetDirective,
    RouterOutlet,

    ViewModule,
    HeaderElementDirective,
    NavigateBackButtonComponent,
    RootModuleNavComponent,
    NavigationTabsMenuComponent,
  ],
})
export class DeploymentSettingsComponent {
  readonly deploymentSettingsTabLinks: TabLink[] = [
    { label: 'Formulare', link: 'form' },
    { label: 'Hilfe Center', link: 'help-center' },
  ];
}
