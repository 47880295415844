<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="Deployment Einstellungen" [rootModuleLink]="['./']" />
</ng-container>

<mp-view [isCustomHeader]="true" [readonly]="true">
  <div class="mp-page mp-page--centered">
    <div class="mp-page__main">
      <div class="page-content mp-page__content">
        <h2 class="page-content__page-name">Deployment Einstellungen</h2>

        <mp-navigation-tabs-menu [tabLinks]="deploymentSettingsTabLinks">
          <router-outlet />
        </mp-navigation-tabs-menu>
      </div>
    </div>
  </div>
</mp-view>
